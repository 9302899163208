<template>
  <div class="homeContainer">
    <div class="topBanner">
      <img src="../assets/loginLogo.png" class="top-line-logo" />
      <div class="topMenu">
        <div v-if="user_type === 'student'" class="mainLeftContent">
          <div
            v-for="item in nav"
            :key="item.name"
            class="navOutBox"
            @click="changeView(item)"
          >
            <div
              :class="{ navInBox: true, selected: currentNav === item.router }"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="navOutBox" @click="changeView('')">退出登录</div>
        </div>
        <div v-if="user_type === 'educator'" class="mainLeftContent">
          <div
            v-for="item in navContact"
            :key="item.name"
            class="navOutBox"
            @click="changeView(item)"
          >
            <div
              :class="{ navInBox: true, selected: currentNav === item.router }"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div v-if="user_type === 'employee'" class="mainLeftContent">
          <div
            v-for="item in navEmployee"
            :key="item.name"
            class="navOutBox"
            @click="changeView(item)"
          >
            <div
              :class="{ navInBox: true, selected: currentNav === item.router }"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="mainRight">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import {
  logout,
  getUserType,
  getUserName,
  getAccountName,
  getAvatarUrl,
} from "../utils/store";

export default {
  data() {
    return {
      nav: [
        {
          name: "官方公告",
          selected: require("../assets/nav/peixun1.png"),
          unselected: require("../assets/nav/peixun.png"),
          router: "/announcements",
        },
        {
          name: "竞赛项目",
          selected: require("../assets/nav/bisai1.png"),
          unselected: require("../assets/nav/bisai.png"),
          router: "/sessionlist",
        },
        // {
        //   name: "成绩排行",
        //   selected: require("../assets/nav/chengji1.png"),
        //   unselected: require("../assets/nav/chengji.png"),
        //   router: "/score",
        // },
        // {
        //   name: "案例分析",
        //   selected: require("../assets/nav/bisai1.png"),
        //   unselected: require("../assets/nav/bisai.png"),
        //   router: "/caselist",
        // },
        {
          name: "个人信息",
          selected: require("../assets/nav/geren1.png"),
          unselected: require("../assets/nav/geren.png"),
          router: "/personalinfo",
        },
        {
          name: "消息",
          selected: require("../assets/nav/xiaoxi1.png"),
          unselected: require("../assets/nav/xiaoxi.png"),
          router: "/message",
        },
        {
          name: "竞赛成绩",
          selected: require("../assets/nav/award1.png"),
          unselected: require("../assets/nav/award.png"),
          router: "/award",
        },
        //  {
        //   name: "成绩排名",
        //   selected: require("../assets/nav/award1.png"),
        //   unselected: require("../assets/nav/award.png"),
        //   router: "/awardList",
        // },
        
        //奖项
        // {name:'消息社交',selected:require('../assets/nav/award1.png'),unselected:require('../assets/nav/award.png'),router:'/award'},//奖项
        // {name: '退出登录', selected: require('../assets/nav/tuichu1.png'), unselected: require('../assets/nav/tuichu.png')}
      ],
      navContact: [
        {
          name: "比赛项目",
          selected: require("../assets/nav/bisai1.png"),
          unselected: require("../assets/nav/bisai.png"),
          router: "/teacherlist",
        },
        {
          name: "个人信息",
          selected: require("../assets/nav/geren1.png"),
          unselected: require("../assets/nav/geren.png"),
          router: "/personalinfo",
        },
        // {name:'消息',selected:require('../assets/nav/xiaoxi1.png'),unselected:require('../assets/nav/xiaoxi.png'),router:'/message'},
        {
          name: "退出登录",
          selected: require("../assets/nav/tuichu1.png"),
          unselected: require("../assets/nav/tuichu.png"),
        },
      ],
      navEmployee: [
        {
          name: "赛前培训",
          selected: require("../assets/nav/peixun1.png"),
          unselected: require("../assets/nav/peixun.png"),
          router: "/peixunadmin",
        },
        {
          name: "比赛项目",
          selected: require("../assets/nav/bisai1.png"),
          unselected: require("../assets/nav/bisai.png"),
          router: "/employeelist",
        },
        {
          name: "个人信息",
          selected: require("../assets/nav/geren1.png"),
          unselected: require("../assets/nav/geren.png"),
          router: "/personalinfo",
        },
        {
          name: "监考",
          selected: require("../assets/nav/bisai1.png"),
          unselected: require("../assets/nav/bisai.png"),
          router: "/examadminlist",
        },
        // {name:'消息',selected:require('../assets/nav/xiaoxi1.png'),unselected:require('../assets/nav/xiaoxi.png'),router:'/message'},
        {
          name: "退出登录",
          selected: require("../assets/nav/tuichu1.png"),
          unselected: require("../assets/nav/tuichu.png"),
        },
      ],
      user_type: getUserType(),
      currentNav: "/peixun",
      student_name: "",
      student_school: "",
      application_project: "",
      avatar_url: "",
    };
  },
  watch: {
    $route(to, from) {
      this.currentNav = this.$route.path;
    },
  },
  mounted() {
    this.$nextTick(() => {
      // 禁用右键
      document.oncontextmenu = new Function("event.returnValue=false");
      // 禁用选择
      document.onselectstart = new Function("event.returnValue=false");
    });
    this.student_name = getUserName();
    this.student_school = getAccountName();
    this.avatar_url = getAvatarUrl();
    this.currentNav = this.$route.path;
  },
  methods: {
    logout() {
      logout();
      location.reload();
    },
    changeView(item) {
      if (item.router) {
        this.currentNav = item.router;
        this.$router.push(item.router);
      } else {
        this.$confirm("Are you sure you want to exit?", "Tips", {
          confirmButtonText: "confirm",
          cancelButtonText: "cancel",
          type: "warning",
        }).then(() => {
          this.logout();
        });
      }
    },
  },
};
</script>

<style scoped="scoped" lang="less">
.content-logout {
  box-sizing: border-box;
  width: 380px;
  position: absolute;
  bottom: 0;
  padding-left: 70px;
  height: 10vh;
  background-color: #577bbc;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 2vh;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.6);

  .iconImg {
    height: 3vh;
    margin-right: 6px;
  }
}

.homeContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  .topBanner {
    position: relative;
    flex-shrink: 0;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 60px;
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: cover;
    background: #00348b;
    min-width: 1100px;
    .top-line-logo {
      height: 44px;
    }
    .topMenu {
      display: flex;
      .mainLeftContent {
        display: flex;
        .navOutBox {
          cursor: pointer;
          font-weight: bold;
          color: white;
          margin-left: 70px;
          .navInBox {
            height: 28px;
            color: white;
            display: flex;
            font-family: Microsoft YaHei;
            font-weight: bold;
          }

          .selected {
            border-bottom: 2px solid white;
          }
        }
      }
    }
  }

  .main {
    padding: 0;
    flex: 1;
    display: flex;
    position: relative;
    overflow: hidden;

    .mainBack {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
    }

    .mainRight {
      background-image: url("../assets/magin_right_bg.png");
      background-repeat: no-repeat;
      background-color: transparent;
      background-size: cover;
      width: 100%;
      height: 100%;
      overflow-y: scroll;
    }
  }
}
</style>
